import Image from "next/image";
import React, { memo } from "react";
import style from "styles/soon.module.css";
import robot from "public/image/robot.webp"
import circleSmall from "public/image/circleSmall.svg"
import useLocale from '../../../../hooks/useLocale'

const Soon = () => {
    const {soonSection} = useLocale()
    return (
        <div id="franshise" className={style.soon}>
            <div className={style.container} data-aos-once="true" data-aos="fade-down" data-aos-anchor-placement="top">
                <div className={style.content} >
                    <div className={style.blockText}>
                        <h2 className={style.noticeText}>{soonSection.title}</h2>
                        <h2 className={style.title}>{soonSection.subtitle}</h2>
                        <h3 className={style.titleIntro}>
                            <img src={circleSmall.src} className={style.imgIntro} alt="" />
                            {soonSection.soonFirstTitle}
                        </h3>
                        <p className={style.aboutIntro}>
                            {soonSection.soonFirstText}
                        </p>
                        <h3 className={style.titleIntro}>
                            <img src={circleSmall.src} className={style.imgIntro} alt="" />
                            {soonSection.soonSecondTitle}
                        </h3>
                        <p className={style.aboutIntro}>
                            {soonSection.soonSecondText}
                        </p>
                        <h3 className={style.titleIntro}>
                            <img src={circleSmall.src} className={style.imgIntro} alt="" />
                            {soonSection.soonThirdTitle}
                        </h3>
                        <p className={style.aboutIntro}>
                            {soonSection.soonThirdText}
                        </p>
                    </div>
                    <div className={style.blockImg}>
                        <div className={style.mobilePoint}>
                            <div className={style.img}>
                                <Image
                                    src={robot}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default memo(Soon);
